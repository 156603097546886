<template lang="pug">
label.checkbox(:class="{ 'alignStart': alignStart }")
  input.checkbox__input(
    :name="name"
    :value="value"
    :type="isRadio ? 'radio' : 'checkbox'"
    :disabled="disabled"
    v-model="model"
  )
  .checkbox__inner(:class="{ 'error': error }")
    checked-icon.checked-icon
  .title(v-if="title !== '' || $slots.default" :class="isRadio ? 'radio' : ''")
    span(v-if="title !== ''") {{title}}
    slot(v-if="$slots.default")
  .error-container(v-if="error")
    slot(name="error")
      span.error-text {{ errorText }}
</template>

<script>
import CheckedIcon from '@/static/icons/check-mark.svg'
export default {
  name: 'AppCheckbox',
  components: {
    CheckedIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    error: {
      type: [Boolean, String],
      default: false
    },
    errorText: {
      type: String,
      default: ''
    },
    alignStart: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    value: {
      type: String || Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &.alignStart {
    align-items: start;
  }

  &:hover .checkbox__inner {
    border-color: $primary;
  }

}
.checkbox__input {
  position: absolute;
  top: get-vw(-100);
  left: get-vw(-10000);
}
.checkbox__inner {
  display: inline-flex;
  width: get-vw(20);
  min-width: get-vw(20);
  height: get-vw(20);
  border-radius: get-vw(3);
  border: get-vw(2) solid $button-xs-text;
  background: transparent no-repeat center;
  cursor: pointer;
  
  &.error {
    border-color: $signal-red;
  }
}

.error-container {
  @include text-t11;
  color: $button-xs-text;
  position: absolute;
  left: get-vw(83);
  top: get-vw(55);
}

.checkbox__input:checked + .checkbox__inner {
  border-color: $primary;
  background-color: $primary;

  .checked-icon {
    display: block;
  }
}

.checked-icon {
  width: get-vw(16);
  height: get-vw(12);
  margin: auto;
  display: none;
}
.checkbox__input {
  position: absolute;
  top: get-vw(-100);
  left: get-vw(-10000);
  &[disabled] + .checkbox__inner {
    cursor: not-allowed;
  }
}
.title, .title a {
  @include text-t4-regular;
  color: $links;
}

a {
  color: $links;
}
.title {
  margin-left: get-vw(16);
  color: $button-xs-text;

  &.radio {
    color: $black;
  }
}

@include tablet {
  .checkbox {
    padding-bottom: get-vw(20, "md");
  }
  .checkbox__inner {
    width: get-vw(12.5, "md");
    height: get-vw(12.5, "md");
    min-width: get-vw(14, "md");
    border-radius: get-vw(3, "md");
    border: get-vw(1.5, "md") solid $button-xs-text;
  }
  .checked-icon {
    width: get-vw(12, "md");
    height: get-vw(8, "md");
  }
  .title {
    @include text-t4-regular-m;
    margin-left: get-vw(8, "md");
  }
  a {
    @include text-t4-regular-underlined-m;
  }
  .error-container {
    @include text-t11-m;
    left: get-vw(23, "md");
    bottom: 0;
    top: unset;
  }
}

@include mobile {
  .checkbox {
    padding-bottom: get-vw(20, "sm");
  }
  .checkbox__inner {
    width: get-vw(14, "sm");
    height: get-vw(14, "sm");
    min-width: get-vw(14, "sm");
    border-radius: get-vw(3, "sm");
    border: get-vw(1.5, "sm") solid $button-xs-text;
  }
  .checked-icon {
    width: get-vw(12, "sm");
    height: get-vw(8, "sm");
  }
  .title {
    @include text-t4-regular-m("sm");
    margin-left: get-vw(8, "sm");
  }
  a {
    @include text-t4-regular-underlined-m("sm");
  }
  .error-container {
    @include text-t11-m("sm");
    left: get-vw(23, "sm");
    bottom: 0;
    top: unset;
  }
}
</style>